<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">与客户一起成功！</div>
        <div class="sub_ttl">
          由专家顾问、研发工程师、客服团队构成的专项小组提供全程服务，确保项目高效落地。
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box4">
      <div class="title">专业服务，落地无忧</div>
      <img class="main_pic" src="@/assets/img/流程3.png" alt="流程" />
      <div class="item i1">
        <div class="ttl">组建专属服务团队</div>
        <div class="desc">
          由专家顾问、研发工程师、客服团队构成的专项小组提供全程服务
        </div>
        <img class="pic" src="@/assets/img/服务/流程_1.jpg" alt="流程_1" />
      </div>
      <div class="item i2">
        <div class="ttl">制定落地制度</div>
        <div class="desc">与客户沟通，根据公司详细情况出台相关落地制度</div>
        <img class="pic" src="@/assets/img/服务/流程_2.jpg" alt="流程_2" />
      </div>
      <div class="item i3">
        <div class="ttl">宣导培训</div>
        <div class="desc">
          提供专业的培训团队，组织线上、线下会议进行价值、使用、制度培训
        </div>
        <img class="pic" src="@/assets/img/服务/流程_3.jpg" alt="流程_3" />
      </div>
      <div class="item i4">
        <div class="ttl">落地实施</div>
        <div class="desc">
          创建公司<img
            class="i"
            src="@/assets/img/ic_24_箭头@3x.png"
            alt=">"
          />创建项目<img
            class="i"
            src="@/assets/img/ic_24_箭头@3x.png"
            alt=">"
          />寄送物料设备<img
            class="i"
            src="@/assets/img/ic_24_箭头@3x.png"
            alt=">"
          />添加分包、班组、工人
        </div>
        <img class="pic" src="@/assets/img/服务/流程_4.jpg" alt="流程_4" />
      </div>
      <div class="item i5">
        <div class="ttl">日常使用及问题处理</div>
        <div class="desc">
          1、7x24小时在线服务；<br />2、多沟通渠道（公司级服务群、项目级服务群、应用内在线交流、
          服务热线：400 004 0686）
        </div>
        <img class="pic" src="@/assets/img/服务/流程_5.jpg" alt="流程_5" />
      </div>
      <div class="item i6">
        <div class="ttl">成果反馈</div>
        <div class="desc">定期为企业提供分析报告，全面分析使用成果</div>
        <img class="pic" src="@/assets/img/服务/流程_6.jpg" alt="流程_6" />
      </div>
    </div>
    <div class="box5">
      <div class="title">来自建设者的认可</div>
      <div class="sub_title">
        健全的服务管理制度，公司和项目管理人员只需在线监督，由墨器星盘服务团队全程协助快速落地，提高工作效率。
      </div>
      <a
        class="contact_btn"
        href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
        target="_blank"
      >
        联系客服
      </a>
      <div class="comment_box">
        <div
          class="wrap"
          :style="{ width: `${23 * doubleComments.length}rem` }"
        >
          <div
            class="comment_item"
            v-for="(item, idx) in doubleComments"
            :key="idx"
          >
            <div class="user" flex="cross:center">
              <img
                class="avt"
                flex-box="0"
                :src="item.user.avt"
                :alt="item.user.name"
              />
              <div class="name" flex-box="1">{{ item.user.name }}</div>
            </div>
            <div class="cnt">
              <img class="l_t" src="@/assets/img/“.png" alt="“" />
              <img class="r_b" src="@/assets/img/“.png" alt="“" />
              <div
                class="l"
                :style="l.style"
                v-for="l in item.cnt"
                :key="l.t || l"
              >
                {{ l.t || l }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="main_box">
        <div class="ttl">服务团队，全球覆盖</div>
        <div class="sub_ttl">
          服务于全球<span class="g">392</span>个城市、<span class="g"
            >1,080</span
          >家企业、<span class="g">12,280</span>个项目、<span class="g"
            >2,000</span
          >万位建设者
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">获取墨器星盘相关服务</div>
      <div class="sub_title">
        联系墨器星盘服务中心，获取产品功能及报价，智慧工地部署支持，项目落地等服务。
      </div>
      <a
        class="contact_btn"
        href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
        target="_blank"
      >
        联系客服
      </a>
    </div>
    <div :style="{ backgroundColor: '#f0f1f6', height: '2rem' }" />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

const box5 = {
  list: [
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/龙源精造新风项目部.png`,
        name: "龙源精造新风项目部",
      },
      cnt: [
        "墨斗团队服务非常好、发信息基本都秒回！",
        {
          t: "赠与墨斗团队“优秀考勤合作伙伴”锦旗",
          style: { color: "#faad14" },
        },
      ],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/贵州民都建设班组.png`,
        name: "贵州民都建设班组",
      },
      cnt: [
        "过去，核算工人工资都是拿着本子到现场一个个核对，经常漏算、错算，财务信息化以后，再也不用担心稀里糊涂亏钱。",
      ],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/楚雄项目水电工人.png`,
        name: "楚雄项目水电工人",
      },
      cnt: [
        "应该大力普及实名制考勤，工资拖欠是我们最担心的问题，现在实名制考勤记录摆在那里，我和我的工友们感觉特别踏实。",
      ],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/榆中人民医院项目管理员.png`,
        name: "榆中人民医院项目管理员",
      },
      cnt: [
        "我比较关注施工进度和劳务成本，现在不用到现场也能监管。值得一提的是，实名制系统让工作对接也更加规范、高效了。",
      ],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/华邦集团安全部企业管理员.png`,
        name: "华邦集团安全部企业管理员",
      },
      cnt: [
        "墨器星盘系统非常适合多项目的监督和管理，管理层可全盘把控工程进度，为企业将来的信息化升级打下了良好的基础。",
      ],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/中建二局青海加西项目管理员.png`,
        name: "中建二局青海加西项目管理员",
      },
      cnt: ["墨器星盘的自动提醒工人打卡功能很强大，极其认可。"],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/华鼎建筑装饰工程有限公司南方经理部.png`,
        name: "华鼎建筑装饰工程有限公司南方经理部",
      },
      cnt: ["墨斗团队的服务和软件系统双百分，满分都不够给。"],
    },
    {
      user: {
        avt: `${process.env.BASE_URL}public/img/头像/三局基建投深圳清平高速项目.png`,
        name: "三局基建投深圳清平高速项目",
      },
      cnt: [
        "不错，很可以。应政府要求协助我们对接了深圳平台，帮我们解决了个大问题。",
      ],
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box5,
    };
  },
  computed: {
    doubleComments() {
      const { list } = this.box5;
      return list.concat(list);
    },
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/服务支持.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 11.5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    width: 35rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box2 {
  background: url("~@/assets/bg/服务团队，全球覆盖.jpg") no-repeat center center;
  background-size: cover;
  height: 45rem;
  overflow: hidden;

  .ttl {
    margin-top: 8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 3rem;
    text-align: center;
  }

  .sub_ttl {
    margin-top: 2rem;
    font-size: 1rem;
    color: #4a4a4a;
    line-height: 2rem;
    text-align: center;

    .g {
      color: #00c271;
      font-size: 1.5rem;
    }
  }
}

.box3 {
  padding: 9.65rem 0;
  background: url("~@/assets/bg/获取墨器星盘相关服务.jpg") no-repeat center
    center;
  background-size: cover;

  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 3rem;
    text-align: center;
  }

  .sub_title {
    margin: 1.6rem auto 0;
    width: 60rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    text-align: center;
  }

  .contact_btn {
    display: block;
    width: 8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-color: #2574ff;
    border-radius: 0.5rem;
    margin: 2.5rem auto;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-decoration: none;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box4 {
  padding: 8.5rem 0 9.2rem;
  overflow: hidden;
  background-color: #fff;
  position: relative;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_pic {
    display: block;
    margin: 6rem auto 0;
    width: 14.7rem;
    height: 93.3rem;
  }

  .item {
    position: absolute;
    width: 26.65rem;
    height: 1rem;

    &.i1,
    &.i3,
    &.i5 {
      left: 50%;
      margin-left: 7.35rem;
    }

    &.i2,
    &.i4,
    &.i6 {
      left: 50%;
      margin-left: -34rem;

      .ttl {
        text-align: right;
      }

      .desc {
        text-align: right;
      }

      .pic {
        float: right;
      }
    }

    &.i1 {
      top: 18.15rem;
    }

    &.i2 {
      top: 33.7rem;
    }

    &.i3 {
      top: 49.25rem;
    }

    &.i4 {
      top: 64.8rem;
    }

    &.i5 {
      top: 80.35rem;
    }

    &.i6 {
      top: 95.9rem;
    }

    .ttl {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      color: #1a1a1a;
      line-height: 1.8rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      text-align: left;
      color: #7b7b7b;
      line-height: 1.1rem;
      vertical-align: middle;

      .i {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0 0.2rem;
      }
    }

    .pic {
      margin-top: 1.1rem;
      display: block;
      width: 17rem;
      height: 10.5rem;
      background-color: #ccc;
    }
  }
}

.box5 {
  padding: 8rem 0 8.8rem;
  background: url("~@/assets/bg/来自建设者的认可.jpg") no-repeat center center;
  background-size: cover;

  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 3rem;
    text-align: center;
  }

  .sub_title {
    margin: 1.6rem auto 0;
    width: 60rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    text-align: center;
  }

  .contact_btn {
    display: block;
    width: 8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-color: #2574ff;
    border-radius: 0.5rem;
    margin: 2.5rem auto;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-decoration: none;

    &:hover {
      background-color: #045af2;
    }
  }

  .comment_box {
    margin-top: 6rem;
    width: 100%;
    overflow: hidden;

    .wrap {
      animation: scroll 40s linear infinite;
      overflow: hidden;
    }
  }

  .comment_item {
    float: left;
    width: 22rem;
    height: 12rem;
    background-color: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(1.25rem);
    margin-right: 1rem;
    border-radius: 0.4rem;
    overflow: hidden;

    .user {
      margin: 1.5rem 1.5rem 0;

      .avt {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
      }

      .name {
        margin-left: 0.6rem;
        font-size: 1rem;
        font-weight: 600;
        color: #1a1a1a;
      }
    }

    .cnt {
      margin: 1.15rem 1.5rem 0 3.7rem;
      position: relative;
      height: 6.25rem;
      padding-top: 1.7rem;
      box-sizing: border-box;

      .l_t,
      .r_b {
        position: absolute;
        width: 1.05rem;
        height: 0.9rem;
      }

      .l_t {
        left: 0;
        top: 0;
        transform: rotate(180deg);
      }

      .r_b {
        right: 0;
        bottom: 0;
      }

      .l {
        font-size: 0.8rem;
        color: #1a1a1a;
        line-height: 1.2rem;
      }
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

@keyframes scroll {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>
